import React from "react"
import Websocket from 'react-websocket'
import { requestLocker, setLocker, setUser } from '../redux/actions/requestLocker'
import { setMode } from '../redux/actions/mode'
import { getSettings } from '../redux/actions/settings'
import axios from '../utils/api'
import { connect } from "react-redux"
import { withRouter } from 'react-router-dom'
import moment from 'moment'

class MyWebsocket extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      timeout: null,
    }
  }

  handleWsOpen = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const token = urlParams.get('Token')
    this.refWebSocket.sendMessage(`{\"token\":\"${token}\"}`);
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search)
    const readerId = urlParams.get('Id')
    const location = urlParams.get('Location')
    const token = urlParams.get('Token')
    if (token == null || location == null || readerId == null) {
      this.props.history.push('/error-no-ids' + window.location.search)
    }
  }

  checkLocation(place) {
    let belongsToPlace = false;

    Object.keys(this.props.occupation).map((key, index) => {
      if (key === place) {
        belongsToPlace = true;
      }
    })

    return belongsToPlace;
  }

  checkExpiration(expiresAt) {

    if (!expiresAt) {

      return true;

    } else if (moment().isBefore(expiresAt)) {

      return true;

    } else {

      return false;

    }

  }

  onCardRead = (data) => {
    clearTimeout(this.state.timeout)

    if (this.props.mode === 'requestLocker') {
      if (data.length == 0) {
        this.requestLocker()
      } else {
        this.props.history.push('/processing-request' + window.location.search)
        this.props.dispatch(setLocker(data[0].LockerNumber, data[0].PlaceId, data[0].Place, data[0].StatusDuration))
          .then(() => {
            this.props.dispatch(setUser(data[0].User))

              .then(() => {
                //this.props.history.push('/info' + window.location.search)

                if (this.checkLocation(data[0].Place)) {
                  if (data[0].Enabled === false) {
                    this.props.history.push('/error-card-disabled' + window.location.search)
                  } else if (moment().isAfter(data[0].StatusExpiresAt)) {
                    this.props.history.push('/error-locker-expired' + window.location.search)
                  } else {
                    this.props.history.push('/info' + window.location.search)
                  }
                } else {
                  this.props.history.push('/error-wrong-location' + window.location.search)
                }

              })
          })
      }
    } else if (this.props.mode === 'returnLocker') {
      this.props.dispatch(setMode('requestLocker'))
      if (data[0] && data[0].User === this.props.user) {
        this.returnLocker()
      } else {
        this.props.history.push('/error-releasing' + window.location.search)
      }
    }
  }

  returnLocker = () => {
    this.props.history.push('/processing-request' + window.location.search)
    const urlParams = new URLSearchParams(window.location.search)
    const readerId = urlParams.get('Id')
    const apiToken = urlParams.get('Token')
    var self = this
    axios.defaults.headers.common = { 'Authorization': `Bearer ${apiToken}` }
    axios.post('/api/reader/?type=FREE_LOCKER&id=' + readerId)
      .then(function (response) {
        console.log(response)
        if (response.data.Result == 'Success') {
          self.props.history.push('/return-locker-confirmation' + window.location.search)
        } else {
          if (response.data.Error === 'L42') {
            self.props.history.push('/error-locker-opened' + window.location.search)
          } else if (response.data.Error === 'L45') {
            self.props.history.push('/error-locker-expired' + window.location.search)
          } else if (response.data.Error === 'L46') {
            self.props.history.push('/error-card-disabled' + window.location.search)
          } else {
            self.props.history.push('/error-releasing' + window.location.search)
          }
        }
      })
      .catch(function (error) {
        console.log(error)
        self.props.history.push('/error-releasing' + window.location.search)
      })
  }

  requestLocker = () => {
    this.props.history.push('/processing-request' + window.location.search)

    const urlParams = new URLSearchParams(window.location.search)
    const readerId = urlParams.get('Id')
    const token = urlParams.get('Token')
    this.props.dispatch(requestLocker(readerId, token))
      .then(() => {
        this.acceptLocker()
      })
      .catch((error) => {
        if (error === 'noLockersAvailable') {
          this.props.history.push('/error-no-lockers-available' + window.location.search)
        } else if (error === 'lockerRecentlyReleased') {
          this.props.history.push('/error-recently-released' + window.location.search)
        } else if (error === 'cardDisabled') {
          this.props.history.push('/error-card-disabled' + window.location.search)
        } else {
          this.props.history.push('/error-assigning-locker' + window.location.search)
        }

      })
  }

  acceptLocker = () => {

    var self = this
    const urlParams = new URLSearchParams(window.location.search)
    const readerId = urlParams.get('Id')
    const token = urlParams.get('Token')
    axios.defaults.headers.common = { 'Authorization': 'Bearer ' + token }
    axios.post('/api/reader/?type=ASSIGN_LOCKER&id=' + readerId)
      .then(function (response) {
        console.log(response)
        self.props.history.push('/info' + window.location.search)
        //self.setTimer()
      })
      .catch(function (error) {
        console.log(error)
        self.props.history.push('/error-assigning-locker' + window.location.search)
      })
  }

  handleWsMessage = (evt) => {
    const urlParams = new URLSearchParams(window.location.search)
    const readerId = urlParams.get('Id')
    const token = urlParams.get('Token')

    if (evt.charAt(0) === '{') {
      const parsedMsg = JSON.parse(evt)
      console.log(parsedMsg)
      if (parsedMsg.MsgType === 'UpdateSettings') {
        this.props.dispatch(getSettings(token))
      }
      if (parsedMsg.ReaderId == readerId && this.props.location.pathname != 'processing-request') {
        this.onCardRead(parsedMsg.Data)
      }
    }
  }

  setTimer = () => {
    const timeout = setTimeout(
      function () {
        this.props.history.push('/' + window.location.search)
      }
        .bind(this),
      3000
    )

    this.setState({ timeout: timeout })
  }

  componentWillUnmount() {
    clearTimeout(this.state.timeout)
  }

  render() {
    const urlParams = new URLSearchParams(window.location.search)
    const token = urlParams.get('Token')

    return (
      <Websocket
        url={process.env.REACT_APP_WS_URL + 'ws/location-status?access_token=' + token}
        onOpen={this.handleWsOpen}
        onMessage={this.handleWsMessage}
        reconnect={true}
        debug={true}
        ref={Websocket => {
          this.refWebSocket = Websocket;
        }}
      />
    )
  }
}

const mapStateToProps = state => {
  console.log(state)
  return {
    location_name: state.occupation.locationName,
    mode: state.mode.mode,
    user: state.requestLocker.user,
    occupation: state.occupation.occupation
  }
}

export default connect(mapStateToProps)(withRouter(MyWebsocket))
