import { combineReducers } from "redux"
import occupation from "./occupation/"
import requestLocker from "./requestLocker/"
import mode from "./mode/"
import settings from "./settings/"

const rootReducer = combineReducers({
  occupation: occupation,
  requestLocker: requestLocker,
  mode: mode,
  settings: settings
})

export default rootReducer
