import React from "react"
import axios from '../../../utils/api'
import {
  Row,
  Col,
  Image
} from "react-bootstrap"
import { connect } from "react-redux"
import { requestLocker } from '../../../redux/actions/requestLocker'
import MyButton from '../../../components/MyButton'

class RequestLockerConfirmation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasLockerBeenSet: false,
      isRequesting: false,
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  componentDidMount() {
    this.acceptLocker()
  }

  acceptLocker = () => {
    this.setState({ isRequesting: true })
    var self = this
    axios.defaults.headers.common = { 'Authorization': 'Bearer YWU2NjNmNTBhOTA1ODBhMTNhMTJkM2ZkYWE0MTBkZDZkYjY0MjhkZjliZjAzMDMyMDhjMzFjZDJiYmRjZjVmMA' }
    axios.post('/api/reader/?type=ASSIGN_LOCKER&id=131')
      .then(function (response) {
        console.log(response)
        self.setState({
          hasLockerBeenSet: true,
          isRequesting: false
        })
        this.timeout = setTimeout(
          function () {
            self.props.history.push('/' + window.location.search)
          }
            .bind(self),
          5000
        )
      })
      .catch(function (error) {
        console.log(error)
        self.setState({ isRequesting: false })
      })
  }

  render() {
    const { locker } = this.props
    const { hasLockerBeenSet, isRequesting } = this.state

    return (
      <Row className="full-height align-items-center">
        <Col>
          <Row className="align-items-center justify-content-center">
            <Col className="request-locker-number-container">
              <Row className="align-items-center justify-content-center">
                <Image src="images/locker_white.svg" className="locker-number-icon" />
                <p className="request-locker-number">{locker}</p>
              </Row>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center">
            <p className="request-locker-text">Cacifo reservado com sucesso!</p>
          </Row>
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = state => {
  console.log(state)
  return {
    location: state.requestLocker.location,
    locker: state.requestLocker.locker.locker_number
  }
}

export default connect(mapStateToProps)(RequestLockerConfirmation)
