import React from "react"
import {
  Row,
  Col,
} from "react-bootstrap"
import { connect } from "react-redux"
import { AlertTriangle } from "react-feather"

class ErrorCardDisabled extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount() {
    this.timeout = setTimeout(
      function () {
        this.props.history.push('/' + window.location.search)
      }
        .bind(this),
      3000
    )
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  render() {

    return (
      <Row className="full-height align-items-center bg-error">
        <Col>
          <Row className="align-items-center justify-content-center pb-3 mt-n5">
            <AlertTriangle size={120} color="white" />
          </Row>
          <Row className="align-items-center justify-content-center">
            <Col xs="12" className="d-flex justify-content-center pt-5">
              <span className="return-locker-text">
                Cartão Desativado!
              </span>
            </Col>
            <Col xs="12" className="d-flex justify-content-center pb-5">
              <span className="return-locker-text">
                Para mais informações por favor dirija-se à vigilância!
              </span>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = state => {
  return {
    
  }
}

export default connect(mapStateToProps)(ErrorCardDisabled)
