import React from "react"
import {
  Row,
  Col,
  Image,
} from "react-bootstrap"
import { connect } from "react-redux"
import MyButton from '../../../components/MyButton'
import CountdownTimer from '../../../components/CountdownTimer'
import { setMode } from '../../../redux/actions/mode'

class ReturnLocker extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentWillUnmount(){
    clearTimeout(this.timeout)
  }

  goBack = () => {
    this.props.history.push('/' + window.location.search)
  }

  componentDidMount() {
    this.timeout = setTimeout(
      function() {
          //change redux state back to mode: requestLocker then go to idle screen
          this.props.dispatch(setMode('requestLocker')).then(() => {
            this.props.history.push('/' + window.location.search)
          })
      }
      .bind(this),
      5000
    )
  }

  render(){
    return (
      <Row className="d-flex align-items-center full-height return-locker-wrapper">
        <CountdownTimer />
        <Col>
          <Row className="justify-content-center">
            <Image className="return-locker-gif" src="images/CardRed.png" />
          </Row>
          <Row className="justify-content-center mt-n5">
            <span className="return-locker-text">
              Passe o cartão no leitor para libertar o seu cacifo.
            </span>
          </Row>
          <Row className="justify-content-center">
            <span className="return-locker-text">
              Certifique-se que não deixa os seus valores.
            </span>
          </Row>
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = state => {
  return {
      location: state.location,
  }
}

export default connect(mapStateToProps)(ReturnLocker)
