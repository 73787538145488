import React from "react"
import { Route } from 'react-router-dom'

import Home from './views/pages/Home'
import IdleScreen from './views/pages/idle'
import Configuration from './views/pages/configuration'
import RequestLocker from './views/pages/request-locker'
import RequestLockerConfirmation from './views/pages/request-locker/confirmation'
import ReturnLocker from './views/pages/return-locker'
import ReturnLockerConfirmation from './views/pages/return-locker/confirmation'
import Occupation from './views/pages/occupation'
import Help from './views/pages/help'
import ErrorAssigningLocker from './views/pages/errors/error-assigning-locker.js'
import ErrorReleasing from './views/pages/errors/error-releasing.js'
import ErrorNoIds from './views/pages/errors/error-no-ids.js'
import ErrorWrongLocation from './views/pages/errors/error-wrong-location.js'
import ErrorNoLockersAvailable from './views/pages/errors/error-no-lockers-available.js'
import ErrorLockerOpened from './views/pages/errors/error-locker-opened.js'
import ErrorLockerExpired from './views/pages/errors/error-locker-expired.js'
import ErrorCardDisabled from './views/pages/errors/error-card-disabled.js'
import ErrorRecentlyReleased from './views/pages/errors/error-recently-released.js'
import Processing from './views/pages/processing'

export default function Router() {
  return (
      <>
        <Route exact path="/" component={IdleScreen} />
        <Route exact path="/info" component={Home} />
        <Route exact path="/configuration" component={Configuration} />
        <Route exact path="/request-locker" component={RequestLocker} />
        <Route exact path="/request-locker-confirmation" component={RequestLockerConfirmation} />
        <Route exact path="/return-locker" component={ReturnLocker} />
        <Route exact path="/return-locker-confirmation" component={ReturnLockerConfirmation} />
        <Route exact path="/occupation" component={Occupation} />
        <Route exact path="/help" component={Help} />
        <Route exact path="/error-assigning-locker" component={ErrorAssigningLocker} />
        <Route exact path="/error-releasing" component={ErrorReleasing} />
        <Route exact path="/error-no-ids" component={ErrorNoIds} />
        <Route exact path="/error-wrong-location" component={ErrorWrongLocation} />
        <Route exact path="/error-no-lockers-available" component={ErrorNoLockersAvailable} />
        <Route exact path="/error-locker-opened" component={ErrorLockerOpened} />
        <Route exact path="/error-locker-expired" component={ErrorLockerExpired} />
        <Route exact path="/error-card-disabled" component={ErrorCardDisabled} />
        <Route exact path="/error-recently-released" component={ErrorRecentlyReleased} />
        <Route exact path="/processing-request" component={Processing} />
      </>
  )
}
