import React from "react"
import {
  Row,
  Col,
} from "react-bootstrap"

class Help extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount(){
    this.timeout = setTimeout(
      function() {
          this.props.history.push('/' + window.location.search)
      }
      .bind(this),
      15000
    )
  }

  componentWillUnmount(){
    clearTimeout(this.timeout)
  }

  close = () => {
    this.props.history.push('/' + window.location.search)
  }

  render(){
    return (
      <Row className="full-height help-top-padding align-items-start justify-content-center bg-cuf-blue">
        <Col lg="5">
          <Row className="justify-content-start">
            <p className="help-text-big">Para obter um cacifo:</p>
          </Row>
          <Row className="justify-content-start">
            <p className="help-text">1º - Passe o cartão no leitor;</p>
          </Row>
          <Row className="justify-content-start">
            <p className="help-text">2º - O número do cacifo que lhe foi atribuído é apresentado no ecrã;</p>
          </Row>
          <Row className="justify-content-start">
            <p className="help-text">3º - Para abrir o seu cacifo, passe o cartão no leitor disponível na localização em causa.</p>
          </Row>
        </Col>
        <Col lg="5">
          <Row className="justify-content-start">
            <p className="help-text-big">Para libertar um cacifo:</p>
          </Row>
          <Row className="justify-content-start">
            <p className="help-text">1º - Passe o cartão no leitor;</p>
          </Row>
          <Row className="justify-content-start">
            <p className="help-text">2º - O número do seu cacifo é apresentado no ecrã;</p>
          </Row>
          <Row className="justify-content-start">
            <p className="help-text">3º - Selecione - Libertar Cacifo;</p>
          </Row>
          <Row className="justify-content-start">
            <p className="help-text">4º - Passe novamente o cartão no leitor para confirmar a ação.</p>
          </Row>
        </Col>
        <Col xs="12" className="d-flex justify-content-center align-items-start">
          <Row className="d-flex justify-content-center align-items-start pb-5">
            <span className="help-text" onClick={this.close} style={{ textDecoration: 'underline' }}>
              Fechar
            </span>
          </Row>
        </Col>
      </Row>
    )
  }
}

export default Help
