const initialState = {
  settings: false
};

export default function rootReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_SETTINGS_LIST':
      return {
        ...state,
        settings: action.payload
      };
    default:
      return state;
  }
}