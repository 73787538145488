import React from "react"
import {
  Row,
  Col,
} from "react-bootstrap"
import { connect } from "react-redux"
import { AlertTriangle } from "react-feather"

class ErrorLockerExpired extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount() {
    this.timeout = setTimeout(
      function () {
        this.props.history.push('/' + window.location.search)
      }
        .bind(this),
      3000
    )
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  render() {

    const { locker, locationName } = this.props

    return (
      <Row className="full-height align-items-center bg-error">
        <Col>
          <Row className="align-items-center justify-content-center pb-3 mt-n5">
            <AlertTriangle size={120} color="white" />
          </Row>
          <Row className="align-items-center justify-content-center">
            <Col xs="12" className="d-flex justify-content-center">
              <span className="return-locker-text">
                Cacifo Expirado!
              </span>
            </Col>
            <Col xs="12" className="d-flex justify-content-center pb-5">
              <span className="return-locker-text">
                Para mais informações por favor dirija-se à vigilância!
              </span>
            </Col>
            <Col xs="12" className="d-flex justify-content-center align-items-center">
              <div className="d-flex justify-content-center align-items-center" style={{ height: '250px', width: '250px', border: '6px solid white', borderRadius: '15px' }}>
                <span className="request-locker-number" style={{ fontSize: '8em' }}>{locker?.locker_number}</span>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center pt-5">
            <p className="request-locker-text">{locationName}</p>
          </Row>
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = state => {
  return {
    locationName: state.requestLocker.locker.locker_location_name,
    locker: state.requestLocker.locker
  }
}

export default connect(mapStateToProps)(ErrorLockerExpired)
