import axios from "../../../utils/api"

export const getSettings = (token) => (dispatch, getState) =>
  new Promise(function (resolve, reject) {
    axios.get('/api/settings/list/', {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    })
      .then(function (response) {
        console.log(response)
        dispatch({
          type: "SET_SETTINGS_LIST",
          payload: response.data.Data
        })
        resolve()
      })
      .catch(function (error) {
        console.log(error)
      })
  })


