import React from "react"
import moment from 'moment'
import 'moment/locale/pt';
import {
  Row,
  Col,
  Image
} from "react-bootstrap"
import { withRouter } from 'react-router-dom'
import { connect } from "react-redux"
import { getPlaceDetails } from '../redux/actions/occupation'

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: null,
    };
  }

  tick() {
    this.setState(state => ({
      date: moment(Date.now()).format('DD-MM-YYYY'),
    }));
  }

  goHome = () => {
    this.props.history.push('/' + window.location.search)
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search)
    const locationId = urlParams.get('Location')
    const token = urlParams.get('Token')
    this.props.dispatch(getPlaceDetails(locationId, token))
    this.interval = setInterval(() => this.tick(), 1000)
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    var date = moment(Date.now()).format('DD MMMM YYYY')

    return (
      <Row className="h-100 bg-white py-5 px-5">
        <Col sm="3" className="d-flex justify-content-start align-items-center">
          <Image onClick={this.goHome} src="images/cuf_logo.png" className="header-logo" />
        </Col>
        <Col sm="6" className="d-flex justify-content-center align-items-center">
          <span className="header-title">
            {process.env.REACT_APP_SCREEN_NAME}
          </span>
        </Col>
        <Col sm="3" className="header-datetime">
          <Row className="d-flex justify-content-end align-items-start px-3">
            <span style={{ fontSize: '1.2em' }}>
              {process.env.REACT_APP_PLACE_NAME}
            </span>
          </Row>
          <Row className="justify-content-end px-3">
            <span style={{ fontSize: '1.0em' }}>
              {date}
            </span>
          </Row>
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = state => {
  return {
    locationName: state.occupation.locationName
  }
}

export default connect(mapStateToProps)(withRouter(Header))
