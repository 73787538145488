import React from "react"
import { Row, Col } from 'react-bootstrap';

class OccupationBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      occupation: 0,
      color: '#40CF9F'
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.occupation != prevState.occupation) {
      if (nextProps.occupation > 95) {
        return {
          occupation: nextProps.occupation,
          color: '#e63333'
        }
      } else if (nextProps.occupation > 75) {
        return {
          occupation: nextProps.occupation,
          color: '#f7902f'
        }
      } else if (nextProps.occupation <= 75) {
        return {
          occupation: nextProps.occupation,
          color: '#40CF9F'
        }
      }
    }
    return null
  }


  getColor(occupation) {
    if (occupation > 95) {

      return '#e63333'

    } else if (occupation > 75) {

      return '#f7902f'

    } else if (occupation <= 75) {

      return '#40CF9F'

    }
  }

  render() {
    const { color, occupation } = this.state
    
    const { places } = this.props

    return (
      <div className="d-flex flex-column w-100">
        {/* <div className="occupation-bar-wrapper" onClick={this.props.refreshOccupation}>
          <p className="occupation-bar-text mb-0">Ocupação</p>
          <div className="occupation-bar-border" style={{ borderColor: color }}>
            <div className="occupation-bar" style={{ width: `${occupation}%`, backgroundColor: color }}></div>
          </div>
          <div className="occupation-bar-percentage">
            <p className="occupation-bar-text">{parseInt(occupation)}%</p>
          </div>
        </div> */}
        {places && Object.keys(places).map((key, index) =>
          <Row key={index} className="d-flex justify-content-center align-items-center w-100 py-1">
            <Col xs="4" className="d-flex justify-content-end align-items-center">
              <span>
                {key}
              </span>
            </Col>
            <Col xs="6" className="d-flex justify-content-start align-items-center">
              <div style={{ width: '100%', backgroundColor: '#f1f1f1', borderRadius: '4px' }} >
                <div style={{ height: '15px', width: `${places[key]}%`, backgroundColor: this.getColor(places[key]), borderRadius: '4px' }} />
              </div>
            </Col>
            <Col xs="2" className="d-flex justify-content-start align-items-center">
              <span>
                {parseInt(places[key])}%
              </span>
            </Col>
          </Row>
        )}
      </div>
    )
  }
}

export default OccupationBar
