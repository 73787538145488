import React from "react"
import {
  Row,
  Col,
  Image,
} from "react-bootstrap"
import { connect } from "react-redux"
import { requestLocker } from '../../../redux/actions/requestLocker'
import MyButton from '../../../components/MyButton'

class RequestLocker extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  goBack = () => {
    this.props.history.push('/' + window.location.search)
  }

  onCardRead = () => {
    this.props.dispatch(requestLocker()).then(() => {
      this.props.history.push('/request-locker-confirmation' + window.location.search)
    }).catch(() => {
      console.log('error')
    })
  }

  componentDidMount() {
    this.timeout = setTimeout(
      function () {
        this.onCardRead()
      }
        .bind(this),
      3000
    )
  }

  render() {
    return (
      <Row className="align-items-center full-height request-locker-wrapper">
        <Col>
          <Row className="justify-content-center">
            <Image className="request-locker-gif" src="images/card.gif" />
          </Row>
          <Row className="justify-content-center">
            <span className="request-locker-text">
              Por favor passe o cartão no leitor para que seja atribuído um cacifo.
            </span>
          </Row>
          <Row className="justify-content-center">
            <MyButton value="Cancelar" onClick={this.goBack} />
          </Row>
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = state => {
  return {
    location: state.location,
  }
}

export default connect(mapStateToProps)(RequestLocker)
