import React from "react"
import { withRouter } from 'react-router-dom'

import Header from '../components/Header'
import Footer from '../components/Footer'
import XmasOverlay from '../components/XmasOverlay'
import HalloweenOverlay from '../components/HalloweenOverlay'
import { Row, Col } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../assets/sass/styles.scss'

class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      xmasMode: '0',
      halloweenMode: '0'
    }
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search)

    const xmasMode = urlParams.get('EnableXmas')
    this.setState({ xmasMode: xmasMode })

    const halloweenMode = urlParams.get('EnableHalloween')
    this.setState({ halloweenMode: halloweenMode })
  }

  render() {
    return (
      <div>
        <Row style={{ height: '15vh' }} >
          <Col>
            <Header />
          </Col>
        </Row>
        <Row style={{ height: '70vh' }}>
          <Col>
            {this.state.xmasMode === '1' &&
              <XmasOverlay />
            }
            {this.state.halloweenMode === '1' &&
              <HalloweenOverlay />
            }
            {this.props.children}
          </Col>
        </Row>
        <Row style={{ height: '15vh' }}>
          <Col>
            <Footer />
          </Col>
        </Row>
      </div>
    )
  }
}

export default withRouter(Layout)
