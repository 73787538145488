import React from "react"
import {
  Row,
  Col,
  Image,
  Modal,
  Button
} from "react-bootstrap"
import { withRouter } from 'react-router-dom'
import { connect } from "react-redux"
import IconButton from '../components/IconButton'
import OccupationBar from '../components/OccupationBar'
import MyButton from '../components/MyButton'
import { getOccupation } from '../redux/actions/occupation'
import axios from 'axios'
import { HelpCircle, Home } from "react-feather"

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: null,
      time: null,
      repeatRequest: null,
      occupationPercent: 0,
      canRefresh: true,
      canRefreshTimeout: null,
      showRequestHelp: false,
      isRequestingAssistance: false,
      showRequestHelpTimeout: null,
      requestHelpNotification: '',
      enableAssistance: '0',
      enableXmas: '0',
      enableHalloween: '0',
    };
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search)

    const enableXmas = urlParams.get('EnableXmas')
    this.setState({ enableXmas: enableXmas })

    const enableHalloween = urlParams.get('EnableHalloween')
    this.setState({ enableHalloween: enableHalloween })

    const enableAssistance = urlParams.get('EnableAssistance')
    this.setState({ enableAssistance: enableAssistance })

    const locationId = urlParams.get('Location')
    const token = urlParams.get('Token')
    var self = this
    self.props.dispatch(getOccupation(token, process.env.REACT_APP_SYSTEM_GENDER))
    var repeatRequest = setInterval(function () {
      self.props.dispatch(getOccupation(token, process.env.REACT_APP_SYSTEM_GENDER))
    }, 30000)
    this.setState({ repeatRequest: repeatRequest })
  }

  componentWillUnmount() {
    clearInterval(this.state.repeatRequest)
    clearTimeout(this.state.canRefreshTimeout)
    clearTimeout(this.state.showRequestHelpTimeout)
  }

  refreshOccupation = () => {
    if (this.state.canRefresh === true) {
      this.setRefreshCooldown()
      const urlParams = new URLSearchParams(window.location.search)
      const locationId = urlParams.get('Location')
      const token = urlParams.get('Token')
      this.props.dispatch(getOccupation(token, process.env.REACT_APP_SYSTEM_GENDER))
    }
  }

  setRefreshCooldown = () => {
    this.setState({ canRefresh: false })
    var self = this
    var canRefreshTimeout = setTimeout(function () {
      self.setState({ canRefresh: true })
    }, 10000)

    this.setState({ canRefreshTimeout: canRefreshTimeout })
  }

  /*calcOccupation = () => {
    var totalLockers = this.props.lockerList.length
    var occupiedLockers = 0
    this.props.lockerList.forEach((item, i) => {
      if(item.status != 0 || item.locker_metra_status != 2){
        occupiedLockers++
      }
    })
    var occupationPercent = Math.round(((occupiedLockers/totalLockers) * 100))
    this.setState({occupationPercent: occupationPercent})
  }*/

  goHome = () => {
    this.props.history.push('/' + window.location.search)
  }

  callAssistance = () => {
    var self = this
    if (!this.state.isRequestingAssistance) {
      clearTimeout(this.state.showRequestHelpTimeout)
      this.setState({ isRequestingAssistance: true })
      axios.post(`http://qa.purpleprofile.pt:5060/call-assistance`, {
        location: this.props.locationName
      })
        .then(res => {
          console.log(res)
          self.notify('O pedido de assistência foi efetuado. Por favor aguarde.')
          self.setState({ isRequestingAssistance: false })
        })
        .catch(error => {
          console.log(error)
          self.notify('Não foi possível pedir assistência.')
          self.setState({ isRequestingAssistance: false })
        })
    }
  }

  notify = (msg) => {
    this.setState({ requestHelpNotification: msg })

    var self = this
    var showRequestHelpTimeout = setTimeout(function () {
      self.setState({
        showRequestHelp: false,
        requestHelpNotification: ''
      })
    }, 6000)

    this.setState({ showRequestHelpTimeout: showRequestHelpTimeout })
  }

  closeModal = () => {
    if (!this.state.isRequestingAssistance) {
      clearTimeout(this.state.showRequestHelpTimeout)
      this.setState({ showRequestHelp: false })
    }
  }

  showModal = () => {
    this.setState({ showRequestHelp: true, requestHelpNotification: '1' })
    var self = this
    var showRequestHelpTimeout = setTimeout(function () {
      self.setState({ showRequestHelp: false })
    }, 6000)

    this.setState({ showRequestHelpTimeout: showRequestHelpTimeout })
  }

  findOneByType(settings){

    let time = false;

    settings.map((item, index) => {
      if(item.type === 'time_until_release'){
        time = item.value
      }
    })

    return time;
  }

  render() {

    const { settings } = this.props

    const defaultFooter =
      <>
        <Col sm="3" className="d-flex justify-content-start align-items-center px-5">
          <Home size={75} onClick={() => this.props.history.push("/" + window.location.search)} />
        </Col>
        <Col sm="6" className="d-flex justify-content-center align-items-center">
          {this.props.occupation &&
            <OccupationBar history={this.props.history} places={this.props.occupation} refreshOccupation={this.refreshOccupation} occupation={this.props.occupation[this.props.locationName]} />
          }
        </Col>
        <Col sm="3" className="d-flex justify-content-end align-items-center px-5">
          <HelpCircle size={75} onClick={() => this.props.history.push("/help" + window.location.search)} />
        </Col>
      </>

    const requestHelpFooter = (
      <Col sm="12" className={`mt-n5 ${this.state.showRequestHelp ? 'appear' : 'disappear'}`}>
        {this.state.requestHelpNotification === '1' &&
          <>
            <Row className="align-items-center justify-content-center footer-text">
              <p>Pretende chamar alguém para lhe prestar assistência?</p>
            </Row>
            <Row className="align-items-center justify-content-center footer-request-help-buttons">
              <Col lg="6">
                <Row className="justify-content-end">
                  <MyButton value="Cancelar" color="danger" onClick={this.closeModal} />
                </Row>
              </Col>
              <Col lg="6">
                <MyButton value="Pedir assistência" color="primary" onClick={this.callAssistance} />
              </Col>
            </Row>
          </>
        }
        {this.state.requestHelpNotification !== '' &&
          <Row className="align-items-center justify-content-center footer-text request-help-notification">
            <p>{this.state.requestHelpNotification}</p>
          </Row>
        }
      </Col>
    )

    return (
      <Row className="h-100 bg-white px-5">
        {this.state.enableAssistance === '1' &&
          <div className="request-help-button" onClick={() => this.showModal()}>
            <Row className="justify-content-center">
              <Image
                src="images/request-assistance.svg"
                className="call-assistance-icon" />
              <p className="call-assistance-text">Pedir assistência</p>
            </Row>
          </div>
        }
        {this.state.showRequestHelp ? requestHelpFooter : defaultFooter}
      </Row>
    )
  }
}

const mapStateToProps = state => {
  return {
    lockerList: state.occupation.lockersList,
    locationName: state.occupation.locationName,
    occupation: state.occupation.occupation,
    settings: state.settings.settings
  }
}

export default connect(mapStateToProps)(withRouter(Footer))
