import React from "react"
import axios from '../../../utils/api'
import {
  Row,
  Col,
  Image,
} from "react-bootstrap"
import { requestLocker, setLocker } from '../../../redux/actions/requestLocker'
import { setMode } from '../../../redux/actions/mode'
import MyButton from '../../../components/MyButton'
import { connect } from "react-redux"
import { getSettings } from '../../../redux/actions/settings'

class IdleScreen extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      hasReadCard: false,
      enableNotice: '0',
    }
  }

  componentDidMount(){
    this.props.dispatch(setMode('requestLocker'))
    const urlParams = new URLSearchParams(window.location.search)

    const enableNotice = urlParams.get('EnableNotice')
    this.setState({enableNotice: enableNotice})

    const readerId = urlParams.get('Id')
    const location = urlParams.get('Location')
    const token = urlParams.get('Token')

    this.props.dispatch(getSettings(token))

    if(token == null || location == null || readerId == null){
      this.props.history.push('/error-no-ids' + window.location.search)
    }
  }

  render(){

    const classname = "request-locker-wrapper";

    return (
      <Row className="align-items-center full-height bg-white">
        {this.state.enableNotice == '1' &&
          <Image
            src="images/door_warning.png"
            className="door-warning"/>
        }
        <Col>
          <Row className="justify-content-center mt-n5">
            <Image className="request-locker-gif" src="images/CardBlue.png" />
          </Row>
          <Row className="justify-content-center mt-n5">
            <span  style={{ fontSize: '2.5em' }}>
              Por favor passe o cartão no leitor.
            </span>
          </Row>
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = state => {
  return {
  }
}

export default connect(mapStateToProps)(IdleScreen)
