import React from "react"
import {
  Row,
  Col,
} from "react-bootstrap"
import ActionButton from '../../components/ActionButton'
import CountdownTimer from '../../components/CountdownTimer'
import MyButton from '../../components/MyButton'
import { connect } from "react-redux"
import { requestLocker } from '../../redux/actions/requestLocker'
import { setMode } from '../../redux/actions/mode'
import moment from 'moment'

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isRequesting: false
    }
  }

  componentDidMount() {
    this.setTimer()
  }

  returnLocker = () => {
    if (this.state.isRequesting === false) {
      clearTimeout(this.state.timeout)
      //change redux state to mode: returnLocker then push to return locker location
      this.props.dispatch(setMode('returnLocker')).then(() => {
        this.props.history.push('/return-locker' + window.location.search)
      })
    } else {
      return null
    }
  }

  setTimer = () => {
    this.timeout = setTimeout(
      function () {
        this.props.history.push('/' + window.location.search)
      }
        .bind(this),
      5000
    )

  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  getLabel() {
    const currentHour = moment().hour();

    const morningStart = 5;
    const afternoonStart = 12;
    const eveningStart = 18;

    if (currentHour >= morningStart && currentHour < afternoonStart) {
      return 'Bom dia';
    } else if (currentHour >= afternoonStart && currentHour < eveningStart) {
      return 'Boa tarde';
    } else {
      return 'Boa noite';
    }
  }

  findOneByType(settings){

    let time = false;

    settings.map((item, index) => {
      if(item.type === 'time_until_release'){
        time = parseInt(item.value) * 60 //multiply to transform value in minutes
      }
    })

    return time;
  }

  render() {
    const { isRequesting } = this.state
    const { locker, locationName, settings } = this.props

    return (
      <Row className="align-items-center full-height info-wrapper">
        <CountdownTimer />
        <Col className="mt-n5">
          <Row className="align-items-center justify-content-center">
            <p className="request-locker-text">{this.getLabel()}, o seu cacifo é o</p>
          </Row>
          <Row className="align-items-center justify-content-center">
            <Col className="request-locker-number-container">
              <Row className="d-flex align-items-center justify-content-center">
                {/*<Image src="images/locker_white.svg" className="locker-number-icon"/>*/}
                <p className="request-locker-number">{locker}</p>
              </Row>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center pt-2">
            <p className="request-locker-text">{locationName}</p>
          </Row>
          <Row className="align-items-center justify-content-center mt-n4">
            {this.props.status_duration > parseInt(this.findOneByType(settings)) &&
              <MyButton value="Libertar cacifo" color="red" class="release-button" onClick={this.returnLocker} />
            }
          </Row>
        </Col>
      </Row>

    )
  }
}

const mapStateToProps = state => {
  console.log(state)
  return {
    locker: state.requestLocker.locker.locker_number,
    locationName: state.requestLocker.locker.locker_location_name,
    user: state.requestLocker.user,
    status_duration: state.requestLocker.locker.status_duration,
    status_expires_at: state.requestLocker.locker.status_expires_at,
    settings: state.settings.settings
  }
}

export default connect(mapStateToProps)(Home)
