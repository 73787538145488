import React from "react"
import {
  Row,
  Col,
} from "react-bootstrap"
import { Frown } from "react-feather"
import { connect } from "react-redux"

class ErrorNoLockersAvailable extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount() {
    this.timeout = setTimeout(
      function() {
          this.props.history.push('/' + window.location.search)
      }
      .bind(this),
      3000
    )
  }

  componentWillUnmount(){
    clearTimeout(this.timeout)
  }

  render(){
    return (
      <Row className="full-height align-items-center bg-error">
      <Col>
        <Row className="align-items-center justify-content-center pb-4">
          <Frown size={120} color="white" />
        </Row>
        <Row className="align-items-center justify-content-center">
          <p className="return-locker-text">Neste momento, não existem cacifos disponíveis!</p>
        </Row>
      </Col>
    </Row>
    )
  }
}

const mapStateToProps = state => {
  return {
      location: state.location,
      locker: state.locker
  }
}

export default connect(mapStateToProps)(ErrorNoLockersAvailable)
