import axios from "../../../utils/api"

export const SET_LOCATION = 'SET_LOCATION'
export const SET_LOCKER = 'SET_LOCKER'
export const SET_USER = 'SET_USER'

export const setLocation = (id) => (dispatch) =>
  new Promise(function (resolve, reject) {
    dispatch({
      type: "SET_LOCATION",
      locationItem: {
        location_id: id
      }
    })
    resolve()
  })

export const setLocker = (locker, location, location_name, status_duration, status_expires_at) => (dispatch, getState) =>
  new Promise(function (resolve, reject) {
    dispatch({
      type: "SET_LOCKER",
      locker: {
        locker_number: locker,
        locker_location: location,
        locker_location_name: location_name,
        status_duration: status_duration,
        status_expires_at: status_expires_at
      }
    })
    resolve()
  })

export const setUser = (user) => (dispatch, getState) =>
  new Promise(function (resolve, reject) {
    dispatch({
      type: "SET_USER",
      payload: {
        user: user
      }
    })
    resolve()
  })

export const requestLocker = (readerId, token) => (dispatch, getState) =>
  new Promise(function (resolve, reject) {
    dispatch({
      type: "SET_LOCKER",
      locker: {
        locker_number: null,
        locker_location: null,
        locker_location_name: null,
        status_duration: null,
        status_expires_at: null
      }
    })
    axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }
    axios.post('/api/reader/?type=GET_LOCKER&id=' + readerId)
      .then(function (response) {
        console.log(response)
        if (response.data.Result === 'Success' && response.data.Data.length > 0) {
          dispatch({
            type: "SET_LOCKER",
            locker: {
              locker_number: response.data.Data[0].LockerNumber,
              locker_location: response.data.Data[0].PlaceId,
              locker_location_name: response.data.Data[0].Place,
              status_duration: response.data.Data[0].StatusDuration,
              status_expires_at: response.data.Data[0].StatusExpiresAt
            }
          })
          resolve()
        } else {
          if (response.data.Error === 'L43') {
            reject('lockerRecentlyReleased')
          } else if (response.data.Error === 'L46') {
            reject('cardDisabled')
          } else {
            reject('noLockersAvailable')
          }
        }
      })
      .catch(function (error) {
        console.log(error)
        reject('requestError')
      })
  })
